import React, { useEffect, useState, useRef } from 'react';
import Block from "../components/Block";
import helper from "../helper";
import CraftSubItem from "../components/CraftSubItem";
import {faHeart, faFilter, faQuestion, faList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "../components/Modal";

function Craft() {
  const [searchTerm, setSearchTerm] = useState('');
  const [craftItems, setCraftItems] = useState([]);
  const [favoriteItems, setFavoriteItems] = useState([]);
  const [selectedCraftItems, setSelectedCraftItems] = useState([]);
  const [selectedItemTypes, setSelectedItemTypes] = useState({
    item: false,
    arms: false,
    head: false,
    chest: false,
    feet: false,
    back: false,
    ring: false,
    neck: false,
    consumable: false,
    seed: false,
    // pickaxe: false,
  });
  const [displayElements, setDisplayElements] = useState({
    craft: true,
    stats: true,
    deep: false,
    inside: false,
    favorite: false,
    all: false,
  });
  const [sort, setSort] = useState('rarity-desc');
  const searchInputRef = useRef(null);
  const [isFiltersShowed, setFiltersShowed] = useState(true);
  const [isGearShowed, setGearShowed] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [gearSlots, setGearSlots] = useState({
    head: {},
    arms: {},
    chest: {},
    feet: {},
    back: {},
    neck: {},
    ring1: {},
    ring2: {},
  });
  const [gearStats, setGearStats] = useState([]);
  const [gearRequirements, setGearRequirements] = useState([]);

  const gearStatTypes = document.config.gear_stat_types;
  const skills = document.config.skills;

  useEffect(() => {
    setCraftItems(document.craftItems);
    setSelectedCraftItems(document.craftItems);
  }, []);

  useEffect(() => {
    const filteredItems = displayElements.favorite
        ? favoriteItems
        : craftItems.filter(craftItem => {
      let isContainConsumes = craftItem.consumes && craftItem.consumes.length > 0;

      // hack to exclude self-consume items
      if (isContainConsumes) {
        const isSelfConsume = craftItem.consumes.find(consumeItem => craftItem.itemName === consumeItem.itemName);
        if (isSelfConsume) {
          return false;
        }
      }

      // search by term
      let isContainTerm = true;
      let term = searchTerm.toLowerCase();
      if (term) {
        if (displayElements.inside) {
          isContainTerm = containsConsumeItemRecursive(craftItem, term);
        } else {
          isContainTerm = craftItem.displayName.toLowerCase().includes(term);
        }
      }

      // basic
      let isPassed = craftItem && isContainTerm;
      if (!displayElements.all) {
        isPassed =
          (isPassed && craftItem.isTradable && isContainConsumes)
          || (isPassed && ['seed', 'back', 'ring', 'neck'].includes(craftItem.type))
        ;
      }

      // item type
      const isSelectedType = Object.values(selectedItemTypes).some(value => value === true);
      if (isSelectedType) {
        isPassed = isPassed && selectedItemTypes[craftItem.type];
      }

      return isPassed;
    });

    switch (sort) {
      case 'rarity-desc':
        filteredItems.sort((a, b) => b.rarity - a.rarity);
        break;
      case 'rarity-asc':
        filteredItems.sort((a, b) => a.rarity - b.rarity);
        break;
      default:
        break;
    }

    // console.log(filteredItems);
    setSelectedCraftItems(filteredItems);
  }, [
    searchTerm,
    craftItems,
    selectedItemTypes,
    sort,
    favoriteItems,
    displayElements.favorite,
    displayElements.inside,
    displayElements.all,
  ]);

  useEffect(() => {
    const updatedStats = [
      {
        stat: "health",
        value: 100,
        calculation: "additive",
      }
    ];
    const updatedRequirements = [];

    Object.values(gearSlots).forEach((gearItem) => {
      if (gearItem?.properties?.gear?.stats) {
        gearItem.properties.gear.stats.forEach((newStat) => {
          const existingStat = updatedStats.find(stat => stat.stat === newStat.stat);
          if (existingStat) {
            existingStat.value += newStat.value;
          } else {
            updatedStats.push({ ...newStat });
          }
        });
      }

      if (gearItem?.properties?.requirements?.skillRequirements) {
        gearItem.properties.requirements.skillRequirements.forEach((newRequirement) => {
          const existingRequirement = updatedRequirements.find(req => req.skillId === newRequirement.skillId);
          if (existingRequirement) {
            existingRequirement.level = Math.max(existingRequirement.level, newRequirement.level);
          } else {
            updatedRequirements.push({ ...newRequirement });
          }
        });
      }
    });

    setGearStats(updatedStats);
    setGearRequirements(updatedRequirements);
  }, [gearSlots]);



  const containsConsumeItemRecursive = (craftItem, searchTerm, deep = 0) => {
    if (!craftItem.consumes || deep >= 10) {
      return false;
    }
    for (const consume of craftItem.consumes) {
      const consumeItem = craftItems.find(item => item.itemName === consume.itemName);
      if (!consumeItem) {
        continue;
      }
      if (consumeItem.displayName.toLowerCase().includes(searchTerm)) {
        return true;
      }
      const childItem = craftItems.find(item => item.itemName === consume.itemName);
      if (childItem && containsConsumeItemRecursive(childItem, searchTerm, ++deep)) {
        return true;
      }
    }
    return false;
  };


  const isItemFavorited = (craftItem) => {
    return favoriteItems.some(item => item.itemName === craftItem.itemName);
  }

  const isItemEquipped = (craftItem) => {
    return craftItem === gearSlots[craftItem.type]
      || [gearSlots.ring1, gearSlots.ring2].includes(craftItem)
    ;
  }

  const toggleFavoriteItem = (craftItem) => {
    const isItemFavorited = favoriteItems.some(item => item.itemName === craftItem.itemName);
    if (isItemFavorited) {
      setFavoriteItems(favoriteItems.filter(item => item.itemName !== craftItem.itemName));
    } else {
      setFavoriteItems([...favoriteItems, craftItem]);
    }
  };

  const toggleEquippedItem = (craftItem) => {
    if (isGear(craftItem)) {
      if (craftItem.type !== 'ring') {
        setGearSlots(prevGearSlots => ({
          ...prevGearSlots,
          [craftItem.type]: craftItem === prevGearSlots[craftItem.type] ? {} : craftItem
        }));
      } else {
        setGearSlots(prevGearSlots => {
          const isRing1Empty = !prevGearSlots.ring1.itemName;
          const isRing2Empty = !prevGearSlots.ring2.itemName;
          if (craftItem === prevGearSlots.ring1) {
            return { ...prevGearSlots, ring1: {} };
          } else if (craftItem === prevGearSlots.ring2) {
            return { ...prevGearSlots, ring2: {} };
          } else if (isRing1Empty) {
            return { ...prevGearSlots, ring1: craftItem };
          } else if (isRing2Empty) {
            return { ...prevGearSlots, ring2: craftItem };
          } else {
            return { ...prevGearSlots, ring1: craftItem };
          }
        });
      }
    }
  };

  const toggleFiltersShowed = () => {
    setFiltersShowed(!isFiltersShowed);
  }

  const toggleGearShowed = () => {
    setGearShowed(!isGearShowed);
  }

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const toggleItemTypes = (event) => {
    const { name, checked } = event.target;
    setSelectedItemTypes(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const toggleDisplayElements = (event) => {
    const { name, checked } = event.target;
    setDisplayElements(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const getItemStats = (craftItem) => {
    if (
      craftItem.properties
      && craftItem.properties.gear
      && craftItem.properties.gear.stats
    ) {
      return craftItem.properties.gear.stats;
    }
    return [];
  }

  const getItemRequirements = (craftItem) => {
    if (
      craftItem.properties
      && craftItem.properties.requirements
      && craftItem.properties.requirements.skillRequirements
    ) {
      return craftItem.properties.requirements.skillRequirements;
    }
    return [];
  }

  const getItemStatText = (gearStat) => {
    let text = '';
    const gearConfig = gearStatTypes.find(entry => entry.stat === gearStat.stat);
    if (!gearConfig) {
      return text;
    }
    text += '+' + gearStat.value + gearConfig.unit + ' ' + gearConfig.displayName;
    return text;
  }

  const getItemRequirementText = (skillRequirement) => {
    let text = '';
    const requirementConfig = skills.find(entry => entry.skillId === skillRequirement.skillId);
    if (!requirementConfig) {
      return text;
    }
    text += requirementConfig.displayName + ' lvl ' + skillRequirement.level;
    return text;
  }

  const getItemByName = (produceName) => {
    return craftItems.find(item => item.itemName === produceName);
  };

  const getItemVendorValue = (craftItem) => {
    if (craftItem && craftItem.properties && craftItem.properties.vendor) {
      return craftItem.properties.vendor.goldValue;
    }
    return null;
  };

  const isGear = (craftItem) => {
    return craftItem.type in gearSlots || craftItem.type === 'ring';
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className="content">
      <div className="wrapper">
        <div className="flex">
          <Block>
            <div className="items-count">{selectedCraftItems.length}</div>
            <div className="craft-items">
              <div className="craft-items-search">
                <div className="search-input-wrapper">
                  <input
                    key={'search-input'}
                    ref={searchInputRef}
                    className="search-input text-field"
                    type="text"
                    placeholder="Search items..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                  {searchTerm &&
                    <div
                      className="search-cross"
                      onClick={() => {
                        setSearchTerm('');
                        searchInputRef.current.focus();
                      }}
                    >&times;</div>
                  }
                </div>
                <button
                  className={'toggle-filters ' + (isGearShowed ? 'active' : '')}
                  onClick={toggleGearShowed}
                >
                  <FontAwesomeIcon icon={faList}/>
                </button>
                <button
                  className={'toggle-filters ' + (isFiltersShowed ? 'active' : '')}
                  onClick={toggleFiltersShowed}
                >
                  <FontAwesomeIcon icon={faFilter}/>
                </button>
                {/*{isFiltersShowed &&*/}
                {/*  <select*/}
                {/*    key={'search-sort'}*/}
                {/*    className="search-sort text-field"*/}
                {/*    value={sort}*/}
                {/*    onChange={handleSortChange}*/}
                {/*  >*/}
                {/*    <option value={'rarity-desc'}>Rarity &#8595;</option>*/}
                {/*    <option value={'rarity-asc'}>Rarity &#8593;</option>*/}
                {/*  </select>*/}
                {/*}*/}
              </div>
              {isFiltersShowed &&
                <div>
                  <div className="craft-items-filters-wrapper">
                    <div className="craft-items-filters">
                      {Object.entries(selectedItemTypes).map(([key, value]) => (
                        <div className="craft-item-filter-type">
                          <input
                            key={'filter-type-' + key}
                            id={'filter-type-' + key}
                            type="checkbox"
                            name={key}
                            checked={value}
                            onChange={toggleItemTypes}
                          />
                          <label key={key} htmlFor={'filter-type-' + key}>
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="craft-items-filters-wrapper">
                    <div className="craft-items-filters">
                    {Object.entries(displayElements).map(([key, value]) => (
                      <div className="craft-item-filter-type">
                        <input
                          key={'filter-type-' + key}
                          id={'filter-type-' + key}
                          type="checkbox"
                          name={key}
                          checked={value}
                          onChange={toggleDisplayElements}
                        />
                        <label key={key} htmlFor={'filter-type-' + key}>
                          {key.charAt(0).toUpperCase() + key.slice(1)}
                        </label>
                        {/*<label className="form-switch">*/}
                        {/*  <span className="form-switch-text">{key.charAt(0).toUpperCase() + key.slice(1)}</span>*/}
                        {/*  <input*/}
                        {/*    key={'filter-type-' + key}*/}
                        {/*    type="checkbox"*/}
                        {/*    name={key}*/}
                        {/*    checked={value}*/}
                        {/*    onChange={toggleDisplayElements}*/}
                        {/*  />*/}
                        {/*  <i/>*/}
                        {/*</label>*/}
                      </div>
                    ))}
                    <button onClick={openModal} className="question-button"><FontAwesomeIcon icon={faQuestion}/></button>
                    <Modal isOpen={isModalOpen} onClose={closeModal}>
                      <ul className="how-to-list">
                        <li><b>Craft</b> - show/hide what the item is assembled from</li>
                        <li><b>Stats</b> - show/hide the item's characteristics and requirements</li>
                        <li><b>Deep</b> - show/hide the crafting tree</li>
                        <li><b>Inside</b> - search within "what the item is crafted from"</li>
                        <li><b>Favorite</b> - display only items marked with hearts</li>
                        <li><b>All</b> - show all items without preliminary filters, including more test and old items</li>
                      </ul>
                    </Modal>
                  </div>
                  </div>
                </div>
              }
              {isGearShowed && (
                <div className="gear-wrapper">
                  <div className="gear-icons">
                    {Object.entries(gearSlots).map(([slot, gearItem]) => {
                      const style = gearItem?.itemName ? helper.getItemIconStyle(gearItem) : {};
                      return (
                        <div
                          key={`gear-slot-${slot}`}
                          className={`gear-icon gear-slot-${slot}`}
                          title={gearItem?.displayName || slot}
                        >
                          {gearItem?.itemName ? (
                            <span
                              className="item"
                              style={style}
                              onClick={() => {
                                toggleEquippedItem(gearItem);
                              }}
                            />
                          ) : (
                            <span className="item item-placeholder" style={{}}/>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="gear-sum">
                    <div className="gear-stats">
                      {gearStats.map(gearStat => (
                        <div className="craft-item-stat" key={`gear-stat-${gearStat.stat}`}>
                          <span>{getItemStatText(gearStat)}</span>
                        </div>
                      ))}
                    </div>
                    <div className="gear-requirements">
                      {gearRequirements.map((gearRequirement) => (
                        <div className="craft-item-requirement" key={`gear-stat-${gearRequirement.skillId}`}>
                          <span>{getItemRequirementText(gearRequirement)}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="craft-items-wrapper">
                {selectedCraftItems && selectedCraftItems.map((craftItem) => {
                // if (!craftItem.consumes || craftItem.consumes && craftItem.consumes.length < 1) {
                //   return;
                // }
                let style = helper.getItemIconStyle(craftItem);
                return (
                  <div
                    className="craft-item"
                    key={craftItem.itemName}
                  >
                    {isGear(craftItem) &&
                      <div
                        key={'gear-button-' + craftItem.itemName}
                        className={'gear-button ' + (isItemEquipped(craftItem) ? 'active' : '')}
                        onClick={() => {
                          toggleEquippedItem(craftItem);
                        }}
                      ><FontAwesomeIcon icon={faList}/></div>
                    }
                    <div
                      // key={'favorite-' + craftItem.itemName}
                      className={'favorite-button ' + (isItemFavorited(craftItem) ? 'active' : '')}
                      onClick={() => {
                        toggleFavoriteItem(craftItem);
                      }}
                    ><FontAwesomeIcon icon={faHeart}/></div>
                    <span key={craftItem.itemName} title={craftItem.displayName} className={'item ' + ('rarity-' + craftItem.rarity)} style={style}/>
                    <span className="craft-item-name" title={craftItem.displayName}>{craftItem.displayName}</span>
                    <span className="craft-item-vendor-price" title="Vendor price">{getItemVendorValue(craftItem)}</span>

                    {craftItem.type === 'seed' &&
                      <div>
                        <div className="craft-item-seeds">
                          {craftItem.properties && craftItem.properties.seed &&
                            (() => {
                              const produceItem = getItemByName(craftItem.properties.seed.produceName);
                              const produceItemStyle = helper.getItemIconStyle(produceItem);
                              return (
                                <div
                                  key={'seed-' + craftItem.itemName + craftItem.properties.seed.produceName}
                                  className="craft-item-seed"
                                >
                                  <div className="seed-properties">
                                    <table>
                                      <tbody>
                                      <tr>
                                        <td className="seed-property-label">Produce</td>
                                        <td className="seed-property-value"><span className="item" title={produceItem.displayName} style={produceItemStyle}/></td>
                                      </tr>
                                      <tr>
                                        <td className="seed-property-label">Sell (gold)</td>
                                        <td className="seed-property-value">{getItemVendorValue(produceItem)}</td>
                                      </tr>
                                      <tr>
                                        <td className="seed-property-label">Disease</td>
                                        <td className="seed-property-value">{craftItem.properties.seed.diseaseChance}%</td>
                                      </tr>
                                      <tr>
                                        <td className="seed-property-label">Grow Hours</td>
                                        <td className="seed-property-value">{craftItem.properties.seed.minGrowthHrs} - {craftItem.properties.seed.maxGrowthHrs}</td>
                                      </tr>
                                      <tr>
                                        <td className="seed-property-label">Produce Qty</td>
                                        <td className="seed-property-value">{craftItem.properties.seed.minProduceQty} - {craftItem.properties.seed.maxProduceQty}</td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                </div>
                              );
                            })()
                          }
                        </div>
                      </div>
                    }

                    {displayElements.craft &&
                      <div>
                        <div className="craft-sub-items">
                          <CraftSubItem
                            item={craftItem}
                            craftItems={craftItems}
                            showDeep={displayElements.deep}
                          />
                        </div>
                      </div>
                    }
                    {displayElements.stats &&
                      <div>
                        <div className="craft-item-stats">
                          {getItemStats(craftItem).map(gearStat => {
                            return (
                              <div
                                key={'stats-' + craftItem.itemName + '-' + gearStat.stat}
                                className="craft-item-stat"
                              >
                                <span>{getItemStatText(gearStat)}</span>
                              </div>
                            );
                          })}
                        </div>
                        <div className="craft-item-requirements">
                          {getItemRequirements(craftItem).map(skillRequirement => {
                            return (
                              <div
                                key={'requirements-' + craftItem.itemName + '-' + skillRequirement.skillId}
                                className="craft-item-requirement"
                              >
                                <span>{getItemRequirementText(skillRequirement)}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    }
                  </div>
                );
              })}
              </div>
            </div>
          </Block>
        </div>
      </div>
    </div>
  );
}

export default Craft;
